<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Considering the reaction shown below, determine which of the following values for the
        equilibrium constant,
        <stemble-latex content="$\text{K,}$" />
        would indicate a greater concentration of species B than species A at equilibrium.
        <b>Select all that apply.</b>
      </p>

      <p class="mb-3 pl-8">
        <chemical-latex content="A <=> B" />
      </p>

      <div v-for="option in options" :key="option.value">
        <v-checkbox
          v-model="inputs.input1"
          class="d-inline-block mb-0"
          :value="option.value"
          :dense="true"
          hide-details="true"
          :ripple="false"
        />
        <stemble-latex :content="option.condition" />
      </div>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question103',
  components: {StembleLatex, ChemicalLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: [],
      },
      options: [
        {condition: '$\\text{K} = 1.00$', value: '1'},
        {condition: '$\\text{K} = 2100$', value: '2'},
        {condition: '$\\text{K} = 2.5\\times 10^{-4}$', value: '3'},
        {condition: '$\\text{K} = 0.64$', value: '4'},
        {condition: '$\\text{K} = -4.50$', value: '5'},
      ],
    };
  },
};
</script>
